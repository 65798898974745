<template>
  <div>
    <!-- Loading -->
    <div class="loading-template" v-if="loading">
      <loading-indicator />
    </div>

    <main-header />

    <!-- Content -->
    <section>
      <div class="container">
        <!-- Breadcrumb -->
        <div class="_flex _center">
          <nav aria-label="breadcrumb" style="height:40px!important">
            <ol class="breadcrumb  bg-transparent tiny">
              <li class="breadcrumb-item"><a href="#">Accueil</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                Mes oeuvres favories
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="container">
        <div class="px-5-md px-8-lg my-10">
          <div class="text-center">
            <h2><b>Mes oeuvres favories</b></h2>
            <p>({{ count }} oeuvre{{ count > 1 ? "s" : "" }})</p>
          </div>
          <div class="my-10">
            <!-- 1 -->
            <div class="card-columns" v-if="authorized">
              <div class="card rounded-0" v-for="item in items" :key="item.id">
                <div class="artists_follow_card artwork-card mb-7">
                  <div class="">
                    <img
                      src="https://d7hftxdivxxvm.cloudfront.net?resize_to=fill&width=400&height=300&quality=80&src=https%3A%2F%2Fd32dm0rphc51dk.cloudfront.net%2FMs2WrkDAKW7zZHZ4QCp6Gw%2Flarge.jpg"
                      class="artword__image"
                    />
                  </div>
                  <div class="mt-2">
                    <div class="d-flex flex-row _between">
                      <div>
                        <div class="bold text-uppercase m-0">
                          Shintaro Miyake
                        </div>
                        <div class="artist-cell-item__bio">
                          <div class="italic">Magdalena della Piazza, 2016</div>
                          <div style="font-style:normal!important;">
                            20 x 16 inch
                          </div>
                        </div>
                        <div class="_flex _between mt-2">
                          <div class="subheading medium">Sculpture</div>
                        </div>
                      </div>
                      <div
                        class="subheading text-right align-self-end"
                        style="line-height:16px;"
                      >
                        <div class="bold text-danger">1280 $</div>
                        <del class="text-muted body-1">950 $</del>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="d-flex w-100 flex-column align-items-center mt-8 mb-20"
              v-if="items.length < 1 && isLoggedIn"
            >
              <i class="fas fa-exclamation-circle" style="font-size:120px"></i>
              <br />
              <h4 style="max-width:400px;text-align:center;font-weight:600">
                Vous n'avez pas des oeuvres favories
              </h4>
            </div>

            <!--  -->
            <not-authenticated />
          </div>
        </div>
      </div>
      <!--  -->
    </section>
    <!--  -->

    <main-footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters(["isLoggedIn", "favorite_artworks"]),
    items() {
      return this.favorite_artworks
    },
    count() {
      return this.favorite_artworks_count
    },
    authorized() {
      if (this.isLoggedIn && this.items.length) return true;
      return false;
    },
  },
  methods: {},
  mounted() {
    this.loading = true;
    this.$store
      .dispatch("fetchFavoriteArtworks")
      .then(() => {
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
      });
  },
};
</script>

<style></style>
